<template>
  <div class="wrapper">
    <LeftNav />
    <div id="content">
      <TopNav />
      <div class="content">
        <Hero />
        <Why />
        <NewWay />
        <Community />
        <Projects />
        <GetYourOwn />
        <Explore />
        <Publications />
        <Future />
        <Footer />
      </div>
    </div>
    <div id="scrollEl">
      <a href="javascript:;" onclick="window.scrollTo(0, 0)">
        <img src="@/assets/images/icon_back_to_top.svg" alt="svg" />
      </a>
    </div>
    <div class="overlay"></div>
  </div>
</template>

<script>
import TopNav from "../components/TopNav";
import Hero from "../components/home/Hero";
import Why from "../components/home/Why";
import NewWay from "../components/home/NewWay";
import Community from "../components/home/Community";
import Projects from "../components/home/Projects";
import GetYourOwn from "../components/home/GetYourOwn";
import Publications from "../components/home/Publications";
import Future from "../components/home/Future";
import Explore from "../components/home/Explore"
import Footer from "../components/Footer";
import LeftNav from "../components/LeftNav";

export default {
  components: {
    TopNav,
    Hero,
    Why,
    NewWay,
    Community,
    Projects,
    GetYourOwn,
    Publications,
    Future,
    Footer,
    LeftNav,
    Explore
  },
  created() {
    window.$(document).ready(function () {
      window.$("#dismiss, .overlay").on("click", function () {
        window.$("#sidebar").removeClass("active");
        window.$(".overlay").removeClass("active");
      });
      window.$("#sidebar ul li a").on("click", function () {
        window.$("#sidebar").removeClass("active");
        window.$(".overlay").removeClass("active");
      });
      window.$("#sidebarCollapse").on("click", function () {
        window.$("#sidebar").addClass("active");
        window.$(".overlay").addClass("active");
        window.$(".collapse.in").toggleClass("in");
        window.$("a[aria-expanded=true]").attr("aria-expanded", "false");
      });

      window.$(window).scroll(function () {
        var scroll = window.$(window).scrollTop();
        if (scroll > 10) {
          window.$("#scrollEl").addClass("active");
        } else {
          window.$("#scrollEl").removeClass("active");
        }
      });
    });
  },
};
</script>

<style scoped>
#scrollEl {
  position: fixed;
  right: 24px;
  bottom: 24px;
  z-index: 5000;
  display: none;
}

#scrollEl.active {
  display: block;
}
</style>