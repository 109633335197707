<!-- center ct -->
<template>
  <div class="hero" id="home">
    <div class="container hide-mobile">
      <h1>Welcome to <br />Innovators</h1>
      <p>All your important Information in one place.</p>
      <br />
      <button @click="register()">Register now</button>
    </div>
    <div class="container show-mobile show-mobile-flex" style="display: none">
      <br />
      <h1>Welcome to <br />Innovators</h1>
      <p>All your important Information in one place.</p>
      <br />
      <button @click="register()">Register now</button>
      <br /><br />
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    register() {
      const wl = window.location;
      const app_url = wl.protocol + "//" + wl.host;
      window.location = `${app_url}/auth/realms/iPortal/protocol/openid-connect/registrations?response_type=code&scope=email+profile+openid&client_id=wordpress&redirect_uri=${app_url}/`;
    },
  },
};
</script>
<style scoped>
.hero {
  background-image: url("../../assets/images/landingpage_new.png");
  /* Set a specific height */
  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.container {
  height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.container.show-mobile {
  display: flex;
  justify-content: flex-end;
}
.hero {
  color: #fff;
}
h1,
p {
  text-align: left;
}
.show-mobile h1{
  margin-bottom: 12px;
  font-size: 2.2em;
  text-align: left;
  width: 100%;
}
.show-mobile p{
  width: 100%;
  font-size: 18px;
}
</style>