<!-- center ct -->
<template>
  <div class="section-ct" id="newWay">
    <div class="container">
      <h1>Go new ways</h1>
      <img src="@/assets/images/go_new_ways.svg" class="section-image" />
      <h2>Document smart – move forward</h2>
      <p>
        Revolutionize your <strong>corporate knowledge management</strong> by
        using our approach, methods, and experiences.
      </p>
      <p>WHY IS OUR APPROACH DIFFERENT?</p>
      <p>
        It’s the best of both worlds – we’ve learnt from the
        <strong>industrial sector</strong> and the
        <strong>Business Information Management. </strong>
      </p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.section-ct {
  color: #000 !important;
  background-color: #FF9052;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>