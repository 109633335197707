<!-- center ct -->
<template>
  <div class="section-ct" id="projects">
    <div class="container">
      <h1>Successful IM projects</h1>
      <div class="section-inner">
        <img src="@/assets/images/projects_global_harmonization.svg" />
        <div class="text" style="text-align: left;">
          <h2>Service Optimization after Transition</h2>
          <p style="text-align: left;">
            An IT service provider took over the global
            <strong>data center management service</strong> for an international
            application and SaaS provider. During and after the service
            transition there was an
            <strong>urgent need for information and documentation</strong> of
            the service <strong>process</strong>, and the related
            <strong>work instructions</strong>. An Information Management
            Project in parallel enabled the new provider team to offer a
            <strong>faster and better service</strong> to the client.
          </p>
        </div>
      </div>
      <div class="section-inner reverse">
        <div class="text" style="text-align: right;">
          <h2>Global Knowledge Management</h2>
          <p style="text-align: right;">
            For a globally operating industrial company the
            <strong>knowledge management process</strong> as well as the
            existing <strong>documentation</strong> and related
            <strong>technologies</strong> were revised and optimized. The
            efficiency and quality in the customer and partner service were
            enhanced by implementing
            <strong>intelligent Information Management</strong> and a central
            <strong>KM platform.</strong>
          </p>
        </div>
        <img src="@/assets/images/projects_post_service.svg" />
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.section-ct {
  color: #000 !important;
  background-color: #ff9052;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section-inner {
  display: flex;
  width: 100%;
}
.text {
  padding: 48px;
  flex: 2;
}
p {
  width: 100%;
}
img {
  flex: 1;
  width: 338px;
}
</style>