<!-- center ct -->
<template>
  <div class="section-ct" id="future">
    <div class="container">
      <h1>Become part of the future</h1>
      <h2>Join the IM team</h2>
      <br />
      <br />
      <img src="@/assets/images/become_part_of_future.svg" />
      <br />
      <br />
      <br />
      <!-- <a
        class="button"
        style="text-align: center"
        href='mailto:avim@avato.net?subject=Trial Version "iPortal"&body=Thanks for your interest in the iPortal! This email will be sent to the avato IM team.%0d%0aWe will contact you soon.%0d%0a%0d%0aPlease leave some information here:%0d%0a- Name:%0d%0a- Surname:%0d%0a- Company:%0d%0a- Role:%0d%0a- When and how may we contact you:%0d%0a- Your message to us:'
        >Apply now</a
      > -->
      <a
        class="button"
        style="text-align: center"
        target="_blank"
        href="https://www.avato-consulting.com/?page_id=51"
        >Apply now</a
      >
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
img {
  width: 50%;
  margin: 0 64px;
}
</style>