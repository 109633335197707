<!-- center ct -->
<template>
  <div class="section-ct" id="publications">
    <div class="container">
      <h1>Publications</h1>
      <div class="row">
        <div class="col">
          <div class="img-ct">
            <img src="@/assets/images/publication_1.svg" />
          </div>
          <div class="text">
            <p>
              Does Knowledge-Centered Service Lead to Knowledge-Centered
              Organization
            </p>
            <a
              href="https://www.linkedin.com/pulse/does-knowledge-centered-service-lead-organization-gregor-bister/"
              target="_blank"
              >Read more <img src="@/assets/images/arrow_white.svg"
            /></a>
          </div>
        </div>
        <div class="col">
          <div class="img-ct">
            <img src="@/assets/images/publication_2.svg" />
          </div>
          <div class="text">
            <p>
              No Operational Excellence Without a Correctly Working Knowledge /
              Information Management
            </p>
            <a
              href="https://www.linkedin.com/pulse/operational-excellence-without-correctly-working-knowledge-bister/"
              target="_blank"
              >Read more <img src="@/assets/images/arrow_white.svg"
            /></a>
          </div>
        </div>
        <div class="col">
          <div class="img-ct">
            <img src="@/assets/images/publication_3.svg" />
          </div>
          <div class="text">
            <p>Regional Innovation Drives Global Digitisation</p>
            <a
              href="https://www.linkedin.com/pulse/regional-innovation-drives-global-digitisation-jennifer-gitt/"
              target="_blank"
              >Read more <img src="@/assets/images/arrow_white.svg"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.section-ct {
  background-color: #e2ebf6;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.img-ct {
  padding: 48px;
}
.row {
  margin: 0;
  width: 100%;
}
.col {
  background-color: #fff;
  margin: 20px;
  text-align: center;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.text {
  background-color: #1D272E;
  color: #fff;
  padding: 24px;
  text-align: left;
  flex: 1;
  display: flex;
  flex-direction: column;
}
p {
  width: 100%;
  text-align: left;
  margin-bottom: 64px;
  flex: 1;
}

.text img {
  height: 34px;
}
</style>