  <!-- Sidebar  -->
<template>
  <nav id="sidebar">
    <div id="dismiss">
      <button type="button" class="sidebarCollapse navbar-btn">
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
    <div class="sidebar-header">
      <!-- <img
        class="lii-wings-down"
        src="@/assets/images/menu_lii_wings_up.svg"
        alt="menu lii wings_up"
      /> -->
    </div>
    <div style="overflow: auto; height: calc(100vh - 88px);">
   
    <ul class="components">
      <li>
        <span class="dot"></span><a href="#why">Why Information Management</a>
      </li>
      <li><span class="dot"></span><a href="#newWay">Go new ways</a></li>
      <li>
        <span class="dot"></span><a href="#community">Join the community </a>
      </li>
      <li>
        <span class="dot"></span><a href="#projects">Successful IM Projects</a>
      </li>
      <li>
        <span class="dot"></span><a href="#ownPortal">Get your own iPortal</a>
      </li>
      <li>
        <span class="dot"></span><a href="#explore">Explore avatos IM World</a>
      </li>
      <li>
        <span class="dot"></span><a href="#publications">Publications</a>
      </li>
      <li>
        <span class="dot"></span><a href="#future">Become part of future</a>
      </li>
    </ul>
    <ul class="components" style="border-bottom: 0">
      <li>
        <span class="dot"></span
        ><a :href="`${app_url}/content/faqs/`" target="_blank">FAQs</a>
      </li>
      <li>
        <span class="dot"></span
        ><a :href="`${app_url}/content/iportal-about/`" target="_blank"
          >Imprint</a
        >
      </li>
    </ul>
     </div>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      app_url: "",
    };
  },
  created() {
    const wl = window.location;
    this.app_url = wl.protocol + "//" + wl.host;
  },
};
</script>

<style scoped>
/** side bar */
#sidebar ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #47748b;
  margin-top: 20px;
}

#sidebar ul.components li {
  padding: 6px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 24px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
  color: #bccdde;
  text-decoration: none;
  padding-left: 24px;
  width: 95%;
}
#sidebar ul li:hover {
  color: #fff;
  background-color: #5b789c;
  text-decoration: none;
}
#sidebar ul li:hover a {
  color: #fff;
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #bccdde;
  background: #25455d;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dot {
  padding: 3px;
  background-color: #b5d0e5;
  border-radius: 50%;
  display: none;
}

#sidebar .sidebar-header {
  padding: 20px;
}

button {
  background-color: #fff;
  color: #000;
  padding: 0;
}
</style>