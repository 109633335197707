<!-- center ct -->
<template>
  <div class="section-ct" id="explore">
    <div class="container">
      <h1>Explore avato's IM world</h1>
      <embed type="image/svg+xml" :src="image" class="section-image large hide-mobile" />
      <iframe
        width="360"
        height="215"
        src="https://www.youtube.com/embed/WuRYiDuXs-I"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        style="display: none;"
        class="show-mobile"
      ></iframe>
    </div>
  </div>
</template>
<script>
import ImageSvg from "@/assets/images/IM_dynamic_graphic.svg";

export default {
  data: function () {
    return {
      image: ImageSvg,
    };
  },
};
</script>
<style scoped>
.section-ct {
  color: #000 !important;
  background-color: #ff9052;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
embed.large {
  width: auto;
}
</style>