<template>
  <!-- footer -->
  <div class="footer-ct">
    <div class="container">
      <div class="footer-logo">
        <img
          src="@/assets/images/logo_iportal_innovators_white.svg"
          alt="svg"
        />
      </div>
      <div class="footer-top-links">
        <div class="footer-nav">
          <a href="mailto:avim@avato.net" style="margin-right: 12px;">Contact</a>
          <a :href="`${app_url}/content/faqs/`" target="_blank">FAQs</a>
        </div>
        <div class="social">
          <a
            href="https://www.youtube.com/channel/UCfXWMp1BiKeY78MAtqDbEWQ/videos"
            target="_blank"
          >
            <img src="@/assets/images/youtube-square-white.svg" alt="svg" />
          </a>
          <a
            href="https://de.linkedin.com/company/avato-consulting-ag"
            target="_blank"
          >
            <img src="@/assets/images/linkedin-white.svg" alt="svg" />
          </a>
          <a
            href="https://www.xing.com/companies/avatoconsultingag "
            target="_blank"
          >
            <img src="@/assets/images/xing-square-white.svg" alt="svg" />
          </a>
        </div>
      </div>
      <div class="footer-bottom-links">
        <a
          href="https://www.avato-consulting.com/?page_id=28728&lang=en"
          target="_blank"
          >Privacy Policy</a
        >&nbsp;|&nbsp;<a
          :href="`${app_url}/content/iportal-about/`"
          target="_blank"
          >Imprint</a
        >
      </div>
      <div class="footer-credits">
        © avato consulting ag - Copyright 2021. All Rights Reserved.
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      app_url: "",
    };
  },
  created() {
    const wl = window.location;
    this.app_url = wl.protocol + "//" + wl.host;
  },
};
</script>
<style scoped>

/** footer ::: start */
.footer-ct {
  background-color: #1D272E;
  color: #fff;
  padding: 24px;
}
.footer-logo img {
  height: 52px;
}
.footer-logo {
  margin-bottom: 24px;
}
.footer-ct a {
  color: #fff;
  text-decoration: none;
}
.footer-ct a:hover {
  text-decoration: underline;
}
.footer-top-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  font-size: 18px;
  border-bottom: 1px solid #fff;
}
.footer-bottom-links {
  height: 48px;
  font-size: 18px;
  margin-top: 24px;
}
.social {
  display: flex;
  justify-content: right;
}
.social a {
  display: block;
  width: 48px;
  margin-left: 12px;
}
.social img {
  width: 32px;
  height: 32px;
}
/** footer ::: end */


</style>