<template>
  <!-- header -->
  <nav class="navbar navbar-expand-md fixed-top bg-light">
    <div class="navbar-brand icon-menu sidebarCollapse" id="sidebarCollapse" style="cursor: pointer;">
      <img src="@/assets/images/menu_button.svg" alt="a vector graphic" />
    </div>
    <a href="#home" class="logo">
      <img src="@/assets/images/innovators_logo_orange.png" alt="a vector graphic" class="logo-icon" style="height: 54px;margin-right: 26px;" />
      <img src="@/assets/images/iportal_innovators.png" alt="a vector graphic"  style="height: 52px;" />
    </a>
    <span style="flex: 1;"></span>
    <div class="actions">
      <img
        class="lii-wings"
        src="@/assets/images/user_login.svg"
        alt="user_login"
      />
      <a
        class="bold"
        :href="`${app_url}/wp/wp-login.php?redirect_to=${app_url}/`"
        >Login</a
      >
      <a
        :href="`${app_url}/auth/realms/iPortal/protocol/openid-connect/registrations?response_type=code&scope=email+profile+openid&client_id=wordpress&redirect_uri=${app_url}/`"
        >Register</a
      >
    </div>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      app_url: "",
    };
  },
  created() {
    const wl = window.location;
    this.app_url = wl.protocol + "//" + wl.host;
  },
};
</script>

<style scoped>
.navbar {
  background-color: #fff !important;
  border: 0;
  height: 90px;
  margin: 0;
  padding: 0 24px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  z-index: 1;
}
.logo img {
  height: 80px;
}
.actions {
  display: flex;
  align-items: center;
}
.actions a {
  font-size: 22px;
  padding: 0 8px;
}
.actions a:hover {
  text-decoration: underline;
}
.actions img {
  width: 48px;
}
</style>