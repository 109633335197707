<!-- center ct -->
<template>
  <div class="section-ct" id="ownPortal">
    <div class="container">
      <h1>Get your own iPortal</h1>
      <h2>Try out a free test version of your own iPortal</h2>
      <div class="row">
        <div>
          <img src="@/assets/images/no_costs.svg" />
          <p>No costs and non-binding</p>
        </div>
        <div>
          <img src="@/assets/images/all_features_included.svg" />
          <p>All features included</p>
        </div>
        <div>
          <img src="@/assets/images/cloud_based.svg" />
          <p>Cloud based</p>
        </div>
      </div>
      <div class="row">
        <div>
          <img src="@/assets/images/available_in_hours.svg" />
          <p>Available within a couple of hours</p>
        </div>
        <div>
          <img src="@/assets/images/support_information_managers.svg" />
          <p>Support by information managers</p>
        </div>
        <div>
          <img src="@/assets/images/after_4_weeks.svg" />
          <p style="padding: 0;">Expires automatically after 4 weeks </p>
        </div>
      </div>
      <br />
      <a
        class="button"
        style="text-align: center"
        href='mailto:avim@avato.net?subject=Trial Version "iPortal"&body=Thanks for your interest in the iPortal! This email will be sent to the avato IM team.%0d%0aWe will contact you soon.%0d%0a%0d%0aPlease leave some information here:%0d%0a- Name:%0d%0a- Surname:%0d%0a- Company:%0d%0a- Role:%0d%0a- When and how may we contact you:%0d%0a- Your message to us:'
        >Free Trial</a
      >
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
img {
  height: 84px;
}
.row {
  margin: 0;
  width: 100%;
  padding: 24px 64px;
}
.row div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 3;
}
p {
  width: 100%;
}
</style>