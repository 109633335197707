<!-- center ct -->
<template>
  <div class="section-ct" id="community">
    <div class="container">
      <h1>Join the community</h1>
      <img src="@/assets/images/join_community.svg" class="section-image" />
      <h2>Benefits of Innovators</h2>
      <p>
        Learn to use the latest methods and <strong>perform</strong> innovative
        information management. Innovators is a platform, where you can
        <strong>exchange</strong> with other professionals and clients.
        <br />
        <strong>Stay up to date</strong> on the latest designs, technologies, compatibilities,
        and content.
      </p>
      <button @click="register()">
        Register now
      </button>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    register() {
      const wl = window.location;
      const app_url = wl.protocol + "//" + wl.host;
      window.location = `${app_url}/auth/realms/iPortal/protocol/openid-connect/registrations?response_type=code&scope=email+profile+openid&client_id=wordpress&redirect_uri=${app_url}/`;
    },
  },
};
</script>
<style scoped>
.section-ct {
  background-color: #E2EBF6;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>