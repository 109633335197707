<!-- center ct -->
<template>
  <div class="why" id="why">
    <div class="container">
      <h1>Why Information Management</h1>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/sk1bGR95FUw"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <br />
      <h2>Think big – start small</h2>
      <p>
        Many companies fail in Documentation because information is missing,
        outdated or untraceable when urgently needed.
      </p>
      <p>
        The iPortal in combination with the support of information managers will
        <strong>guarantee successful information management.</strong>
      </p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.container {
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
}
</style>